import React from 'react'
import CookiePolicy from '../../../components/Legal/CookiePolicy'

const Page = ({ location }) => {
    return (
        <CookiePolicy
            language="en"
            location={location}
        />
    )
}

export default Page
