import React from 'react'
import ReactMarkdown from 'react-markdown'
import Layout from '../../components/Layout'
import SEO from '../../components/seo'
import localization from '../../localization'
import LegalNav from './LegalNav'

import Cookies from './Text/Cookies'
import { legal } from './index.module.scss'
import SubpageHero from '../SubpageHero'

const CookiePolicy = ({ language, location }) => {
    return (
        <>
            <SEO
                title={localization[language].terms.cookies}
                description={localization[language].terms.cookies}
            />
            <Layout isFrontpage>
                <SubpageHero>
                    <div>
                        <h1>{localization[language].terms.cookies}</h1>
                        <LegalNav language={language} location={location} />
                    </div>
                </SubpageHero>
                <div className={legal}>
                    <ReactMarkdown
                        children={Cookies[language]}
                        allowDangerousHtml={true}
                        escapeHtml={false}
                        skipHtml={false}
                        linkTarget="_blank"
                    />
                </div>
            </Layout>
        </>
    )
}

export default React.memo(CookiePolicy)
